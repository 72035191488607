import React from "react"
import Layout from "@components/layout"
import Meta from "@components/meta"
import * as Heading from "@components/heading"
import BreadCrumbs from "@components/breadcrumbs";
import { Link } from "gatsby"
import Chevron from "@svgs/chevron.svg";
import { StaticImage } from "gatsby-plugin-image"

const Business = () => (
  <Layout>
    <Meta
      title="倉敷営業所を開設しました"
      desc="2022年12月に倉敷営業所を開設しました"
    />{ }
    <Heading.H1 text="倉敷営業所を開設しました" />
    <div className="px-5 mb-12 lg:max-w-4xl lg:mx-auto">
      <BreadCrumbs
        lists={[
          {
            string: "会社情報",
            path: "/company",
          },
          {
            string: "お知らせ",
            path: "/news",
          },
          {
            string: "倉敷営業所を開設しました",
            path: "/kurashiki",
          }
        ]}
      />

      <div className="lg:flex lg:flex-row">
        <StaticImage src="../images/kurashiki01.jpg" className="w-full mb-8 lg:mt-5 lg:w-96 lg:flex-shrink-0" />
        <p className="mb-4 lg:block lg:py-5 lg:pl-10">
        かねてより準備してまいりました倉敷営業所がおかげさまでこのたび無事に竣工の運びとなりました。
        これもひとえに皆様の日ごろのご厚情、ご支援の賜物と深く感謝申し上げます。
        ふたたび倉敷地区のお客様へ強みを生かしたサービスを行ってまいります。
        今後ともご期待にお応えできるよう取り組みますので何卒よろしくお願いいたします。
        </p>
      </div>
      <div className="lg:flex lg:flex-row-reverse">
        <StaticImage src="../images/kurashiki02.jpg" className="w-full mb-8 lg:mt-5 lg:w-96 lg:flex-shrink-0" />
        <p className="lg:block lg:py-5 lg:pr-10">
        2022年11月25日に開所式を執り行いました。
        富士電機をはじめ、多くの取引業者様が来訪頂き、盛大にお披露目をさせて頂きました。
        ありがたいお言葉や、お祝いをいただき、倉敷営業所員全員新たな気持ちで仕事に取り組みます！！
        </p>
      </div>
      <div className="lg:flex lg:flex-row">
        <StaticImage src="../images/kurashiki03.jpeg" className="w-full mb-8 lg:mt-5 lg:w-96 lg:flex-shrink-0" />
        <p className="mb-4 lg:block lg:py-5 lg:pl-10">
        2022年12月1日より本格的に倉敷営業所がスタートいたしました。
        総勢7名で倉敷・水島地区のお客様に地域と営業力を活かしたサービスを行っていきます。
        今後ともよろしくお願いいたします。
        </p>
      </div>
    </div>
  </Layout>
)

export default Business;