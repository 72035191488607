import React from "react"
import Chevron from "@svgs/chevron.svg";
import { Link } from "gatsby"

export default function BreadCrumbs({ lists }) {
    if(!lists){ 
      return('')
    }
    
    return (
      <ol className="flex px-4 -mt-6 mb-8" aria-label="breadcrumb">
        {lists.map(({ string, path }, index) => (
            <li className="flex items-center" key={index}>
              {lists.length - 1 !== index 
                ?
                <>
                  <Link className="text-sm mr-2" to={path}>{string}</Link>
                  <Chevron className="h-3 mt-1 text-gray-500" />
                </>
                : <span className="text-sm" aria-current="page">{string}</span>
              }
            </li>
        ))}
      </ol>
    );
  }